import React, { useState } from 'react';
import axios from 'axios';

import Layout from '../../components/Layout';
import Personal from '../../components/Personal';
import HomeInfo from '../../components/HomeInfo';

const API_URL = process.env.GATSBY_API_URL || 'http://localhost:9000';

const Renters = () => {
  const [personalInfo, setPersonalInfo] = useState({});
  const [info, setInfo] = useState({
    rentalType: 'home',
    sameAddress: '',
    city: '',
    state: '',
    zip: '',
    address: '',
    personalPropertyLimit: '',
    dog: '',
    dogCount: '',
    dogBreed: '',
    desiredEffectiveDate: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const onInfoChange = (key, value) => {
    setInfo({ ...info, [key]: value });
  };
  const onSubmit = async () => {
    try {
      await axios.post(`${API_URL}/quote`, {
        personalInfo,
        info,
      });
      setSubmitted(true);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Layout fullMenu>
      <article id="main">
        <header style={{ height: 300 }}>
          <h2>Renters Insurance</h2>
        </header>
        {submitted ? (
          <section className="wrapper style5">
            <h3 style={{ textAlign: 'center' }}>
              Thanks for submitting your info - Agency Insurance will be in
              contact with you shortly!
            </h3>
          </section>
        ) : (
          <section className="wrapper style5">
            <div
              className="inner"
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-around',
                flexWrap: 'wrap',
              }}
            >
              <h3 style={{ width: '100%', marginBottom: 0 }}>Personal Info</h3>
              <Personal
                onChange={setPersonalInfo}
                showAddress={true}
                showOccupation={false}
                showComments={false}
              />
              <h3 style={{ width: '100%', marginBottom: 0 }}>Rental Info</h3>
              <div style={{ width: '100%' }}>
                <label style={{ width: '40%', marginTop: 0, marginRight: 20 }}>
                  Rental Type
                  <select
                    onChange={(e) => onInfoChange('rentalType', e.target.value)}
                  >
                    <option value="home">Home</option>
                    <option value="apartment">Apartment</option>
                    <option value="townhouse">Townhouse</option>
                    <option value="condo">Condo</option>
                  </select>
                </label>
                <label style={{ width: '100%', marginTop: 0, marginRight: 20 }}>
                  Property to Insure* <br />
                  <input
                    type="radio"
                    id="same"
                    name="sameAddress"
                    onChange={() => onInfoChange('sameAddress', 'same')}
                  />
                  <label htmlFor="same">Same Address as Above</label>
                  <input
                    type="radio"
                    id="different"
                    name="sameAddress"
                    onChange={() => onInfoChange('sameAddress', 'different')}
                  />
                  <label htmlFor="different">Different Address</label>
                </label>
                {info.sameAddress == 'different' && (
                  <>
                    <label
                      style={{ width: '100%', marginTop: 0, marginRight: 20 }}
                    >
                      Street Address*
                      <input
                        type="text"
                        name="address"
                        id="address"
                        onChange={(e) =>
                          onInfoChange('address', e.target.value)
                        }
                      />
                    </label>
                    <label
                      style={{
                        width: '30%',
                        minWidth: 300,
                        marginTop: 0,
                        marginRight: 20,
                      }}
                    >
                      City*
                      <input
                        type="text"
                        name="city"
                        id="city"
                        onChange={(e) => onInfoChange('city', e.target.value)}
                      />
                    </label>
                    <label
                      style={{
                        width: '30%',
                        minWidth: 300,
                        marginTop: 0,
                        marginRight: 20,
                      }}
                    >
                      State*
                      <input
                        type="text"
                        name="state"
                        id="state"
                        onChange={(e) => onInfoChange('state', e.target.value)}
                      />
                    </label>
                    <label
                      style={{
                        width: '30%',
                        minWidth: 300,
                        marginTop: 0,
                        marginRight: 20,
                      }}
                    >
                      Zip Code*
                      <input
                        type="text"
                        name="zip"
                        id="zip"
                        onChange={(e) => onInfoChange('zip', e.target.value)}
                      />
                    </label>
                  </>
                )}
                <label style={{ width: '100%', marginTop: 0, marginRight: 20 }}>
                  Personal Property Limit ($)
                  <input
                    type="text"
                    name="personalPropertyLimit"
                    id="personalPropertyLimit"
                    onChange={(e) =>
                      onInfoChange('personalPropertyLimit', e.target.value)
                    }
                  />
                </label>
                <label style={{ width: '100%', marginTop: 0, marginRight: 20 }}>
                  Dog?
                  <br />
                  <input
                    type="radio"
                    id="dogYes"
                    name="dog"
                    onChange={() => onInfoChange('dog', 'yes')}
                  />
                  <label htmlFor="dogYes">Yes</label>
                  <input
                    type="radio"
                    id="dogNo"
                    name="dog"
                    onChange={() => onInfoChange('dog', 'no')}
                  />
                  <label htmlFor="dogNo">No</label>
                </label>
                {info.dog == 'yes' && (
                  <>
                    <label
                      style={{ width: '22%', marginTop: 0, marginRight: 20 }}
                    >
                      # of Dogs
                      <input
                        type="text"
                        name="dogCount"
                        id="dogCount"
                        onChange={(e) =>
                          onInfoChange('dogCount', e.target.value)
                        }
                      />
                    </label>
                    <label
                      style={{ width: '22%', marginTop: 0, marginRight: 20 }}
                    >
                      Breed(s)
                      <input
                        type="text"
                        name="dogBreed"
                        id="dogBreed"
                        onChange={(e) =>
                          onInfoChange('dogBreed', e.target.value)
                        }
                      />
                    </label>
                  </>
                )}
                <label style={{ width: '100%', marginTop: 0, marginRight: 20 }}>
                  Desired Effective Coverage Date
                  <input
                    type="date"
                    name="desiredEffectiveDate"
                    id="desiredEffectiveDate"
                    onChange={(e) =>
                      onInfoChange('desiredEffectiveDate', e.target.value)
                    }
                  />
                </label>
              </div>
            </div>
            <div className="inner">
              <button onClick={onSubmit}>Submit</button>
            </div>
          </section>
        )}
      </article>
    </Layout>
  );
};

export default Renters;
