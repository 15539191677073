import React, { useState } from 'react';
import Layout from './Layout';
import axios from 'axios';
const API_URL = process.env.GATSBY_API_URL || 'http://localhost:9000';

const HomeInfo = ({ onChange }) => {
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const [info, setInfo] = useState({
    sameAddress: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    roofAge: '',
    roofType: 'architectural-shingle',
    ageHeating: '',
    siding: 'wood',
  });

  const onInfoChange = (key, value) => {
    setInfo({ ...info, [key]: value });
    onChange({ ...info, [key]: value });
  };

  return (
    <form
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 0,
      }}
    >
      <label style={{ width: '100%', marginTop: 0, marginRight: 20 }}>
        Property to Insure* <br />
        <input
          type="radio"
          id="same"
          name="sameAddress"
          onChange={() => onInfoChange('sameAddress', 'same')}
        />
        <label htmlFor="same">Same Address as Above</label>
        <input
          type="radio"
          id="different"
          name="sameAddress"
          onChange={() => onInfoChange('sameAddress', 'different')}
        />
        <label htmlFor="different">Different Address</label>
      </label>
      {info.sameAddress == 'different' && (
        <>
          <label style={{ width: '100%', marginTop: 0, marginRight: 20 }}>
            Street Address*
            <input
              type="text"
              name="address"
              id="address"
              onChange={(e) => onInfoChange('address', e.target.value)}
            />
          </label>
          <label
            style={{
              width: '30%',
              minWidth: 300,
              marginTop: 0,
              marginRight: 20,
            }}
          >
            City*
            <input
              type="text"
              name="city"
              id="city"
              onChange={(e) => onInfoChange('city', e.target.value)}
            />
          </label>
          <label
            style={{
              width: '30%',
              minWidth: 300,
              marginTop: 0,
              marginRight: 20,
            }}
          >
            State*
            <input
              type="text"
              name="state"
              id="state"
              onChange={(e) => onInfoChange('state', e.target.value)}
            />
          </label>
          <label
            style={{
              width: '30%',
              minWidth: 300,
              marginTop: 0,
              marginRight: 20,
            }}
          >
            Zip Code*
            <input
              type="text"
              name="zip"
              id="zip"
              onChange={(e) => onInfoChange('zip', e.target.value)}
            />
          </label>
        </>
      )}
      <label style={{ width: '40%', marginTop: 0, marginRight: 20 }}>
        Age of Roof (years)
        <input
          type="text"
          name="roofAge"
          id="roofAge"
          onChange={(e) => onInfoChange('roofAge', e.target.value)}
        />
      </label>
      <label style={{ width: '40%', marginTop: 0, marginRight: 20 }}>
        Roof Type
        <select onChange={(e) => onInfoChange('roofType', e.target.value)}>
          <option value="architectural-shingle">Architectural Shingles</option>
          <option value="asphalt">Asphalt</option>
          <option value="wood-shingle">Clay Tile</option>
          <option value="concrete">Concrete</option>
          <option value="tile">Tile</option>
          <option value="metal">Metal</option>
          <option value="slate">Slate</option>
        </select>
      </label>
      <label
        style={{
          width: '30%',
          minWidth: 300,
          marginTop: 0,
          marginRight: 20,
        }}
      >
        Exterior Siding
        <select onChange={(e) => onInfoChange('siding', e.target.value)}>
          <option value="wood">Wood</option>
          <option value="brick">Brick</option>
          <option value="vinyl">Vinyl</option>
          <option value="hardie">Hardie</option>
          <option value="stucco">Stucco</option>
          <option value="log">Log</option>
          <option value="stone">Stone</option>
        </select>
      </label>
      <label
        style={{
          width: '30%',
          minWidth: 300,
          marginTop: 0,
          marginRight: 20,
        }}
      >
        Age of Heating (years)
        <input
          type="text"
          name="heating"
          id="heating"
          onChange={(e) => onInfoChange('ageHeating', e.target.value)}
        />
      </label>
    </form>
  );
};

export default HomeInfo;
